import React from 'react'

// returns true if available, false if sold out
export function cycleCotas(cabana) {
    let available = 0;
    let status = 'v';

    if (cabana.cotas) {
        for(var i = 0; i < cabana.cotas.length; i++) {
            let cota = cabana.cotas[i];
            
            if (cota.status === 'd') {
                if(status !== 'r') status = 'd';
                available++;
            }
            if (cota.status === 'r') {
                status = 'r';
            }
        }
    }

    return {status, available}
}

// simple component containing an svg icon
export function CabanaIcon() {
    return ( 
        <svg id="Grupo_198" data-name="Grupo 198" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path id="Caminho_203" data-name="Caminho 203" d="M45.5,39.5H45v-2a.5.5,0,0,0-.5-.5h-1V24h2a.5.5,0,0,0,.369-.838l-5-5.5A.493.493,0,0,0,40.5,17.5H34.937A2,2,0,0,0,33,16H29a2,2,0,0,0-1.938,1.5H21.5a.506.506,0,0,0-.369.163l-5,5.5A.5.5,0,0,0,16.5,24h2V37h-1a.5.5,0,0,0-.5.5v2h-.5a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5H18v3a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-3h4v3a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-3h6v3a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-3h4v3a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,.5-.5v-3h1.5A.5.5,0,0,0,46,42V40A.5.5,0,0,0,45.5,39.5Zm-6-21a.522.522,0,0,0-.281.081l-1.225.813-1.225-.812a.462.462,0,0,0-.281-.081ZM29,17h4a1.006,1.006,0,0,1,.869.5H28.137A.988.988,0,0,1,29,17Zm3.5,1.5a.522.522,0,0,0-.281.081l-1.225.813-1.225-.812a.462.462,0,0,0-.281-.081Zm-7,0a.522.522,0,0,0-.281.081l-1.225.813-1.225-.812a.462.462,0,0,0-.281-.081ZM17.631,23l4.094-4.5H22.5a.5.5,0,0,0-.275.919l1.5,1a.506.506,0,0,0,.55,0l1.5-1a.5.5,0,0,0,.138-.694A.508.508,0,0,0,25.5,18.5h3.994a.5.5,0,0,0-.275.919l1.5,1a.506.506,0,0,0,.55,0l1.5-1a.5.5,0,0,0,.138-.694.508.508,0,0,0-.413-.225H36.5a.5.5,0,0,0-.275.919l1.5,1a.506.506,0,0,0,.55,0l1.5-1a.5.5,0,0,0,.138-.694A.508.508,0,0,0,39.5,18.5h.775L44.369,23H17.631Zm9.7,7H32.5v2h-5V30.75A1.76,1.76,0,0,0,27.331,30Zm.169,6h15v1h-15Zm0-1V33h5v.5a.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5V33h2v2Zm6-2V29.5H36V33ZM37,33V29.5h2.5V33Zm3.5-1V30h2v2Zm0-3a.5.5,0,0,0-.5-.5H33a.5.5,0,0,0-.5.5h-13V27h23v2Zm-14,1.75V37h-5V30.75a.752.752,0,0,1,.75-.75h3.5A.752.752,0,0,1,26.5,30.75ZM19.5,35V33h1v2Zm1,1v1h-1V36Zm-1-4V30h1.169a1.76,1.76,0,0,0-.169.75V32Zm0-6V24h23v2ZM18,38H44v1.5H18Zm2,7H19V42.5h1Zm7,0H26V42.5h1Zm9,0H35V42.5h1Zm7,0H42V42.5h1Zm2-3.5H17v-1H45Z" transform="translate(-16 -16)" fill="#ffc038"/>
            <path id="Caminho_204" data-name="Caminho 204" d="M75.608,96.939a.5.5,0,1,0-.556-.831l-1.225.813L72.6,96.108a.5.5,0,1,0-.556.831l1.5,1a.506.506,0,0,0,.55,0Zm6,0a.5.5,0,0,0-.556-.831l-1.225.813L78.6,96.108a.5.5,0,0,0-.556.831l1.5,1a.506.506,0,0,0,.55,0Zm13,0a.5.5,0,0,0-.556-.831l-1.225.813L91.6,96.108a.5.5,0,1,0-.556.831l1.5,1a.506.506,0,0,0,.55,0Zm-6,0a.5.5,0,0,0-.556-.831l-1.225.813L85.6,96.108a.5.5,0,0,0-.556.831l1.5,1a.506.506,0,0,0,.55,0ZM74.833,108.02h0a.5.5,0,1,0,.5.5A.5.5,0,0,0,74.833,108.02Z" transform="translate(-68.333 -91.02)" fill="#ffc038"/>
        </svg> 
    )
}